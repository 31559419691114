import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/Dashboard.css";
import Navmenu from "../components/Navmenu";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">{t("dashboard")}</h1>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card text-white bg-primary mb-3">
                <div className="card-header">{t("total_auto_parts")}</div>
                <div className="card-body">
                  <h5 className="card-title">150</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card text-white bg-success mb-3">
                <div className="card-header">{t("orders_completed")}</div>
                <div className="card-body">
                  <h5 className="card-title">75</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card text-white bg-warning mb-3">
                <div className="card-header">{t("pending_orders")}</div>
                <div className="card-body">
                  <h5 className="card-title">25</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">{t("recent_activity")}</div>
                <div className="card-body">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{t("description")}</th>
                        <th>{t("date")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{t("order_completed", { orderId: "1234" })}</td>
                        <td>2024-11-01</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          {t("new_auto_part_added", { part: "Brake Pads" })}
                        </td>
                        <td>2024-11-02</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>{t("order_pending", { orderId: "1235" })}</td>
                        <td>2024-11-03</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
