import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/Register.css";
import { register } from "../../config/api";
import Navmenu from "../../components/Navmenu";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    role: "user",
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      await register(formData);
      setSuccessMessage(t("registration_success"));
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 2000);
    } catch (err) {
      console.error("Registration failed", err);
      setError(t("registration_error"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 d-flex flex-column justify-content-center align-items-center">
          <div
            className="card shadow-lg p-4"
            style={{ maxWidth: "500px", width: "100%" }}
          >
            <h2 className="text-center mb-4">{t("register_title")}</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  {t("full_name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder={t("full_name_placeholder")}
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  {t("username")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder={t("username_placeholder")}
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  {t("email")}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder={t("email_placeholder")}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  {t("password")}
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder={t("password_placeholder")}
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  {t("role")}
                </label>
                <select
                  className="form-control"
                  id="role"
                  value={formData.role}
                  onChange={handleChange}
                >
                  <option value="user">{t("user")}</option>
                  <option value="moderator">{t("moderator")}</option>
                  <option value="admin">{t("admin")}</option>
                </select>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <button
                  type="submit"
                  className="btn btn-success w-100 mt-3"
                  disabled={isLoading}
                >
                  {isLoading ? t("registering") : t("register")}
                </button>
              </div>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Register;
