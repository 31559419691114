import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const savedLanguage = localStorage.getItem("language") || "es";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        dashboard: "Dashboard",
        articles: "Articles",
        article_list: "List of Articles",
        create_article: "Create New Article",
        categories: "Categories",
        brand_list: "List of Brands",
        create_brand: "Create New Brand",
        users: "Users",
        user_list: "List of Users",
        create_user: "Create New User",
        total_auto_parts: "Total Auto Parts",
        orders_completed: "Orders Completed",
        pending_orders: "Pending Orders",
        recent_activity: "Recent Activity",
        description: "Description",
        date: "Date",
        order_completed: "Order #{{orderId}} completed",
        new_auto_part_added: "New auto part added: {{part}}",
        order_pending: "Order #{{orderId}} pending",
        code: "Code",
        alt_code: "Alternative Code",
        description: "Description",
        price: "Price",
        stock: "Stock",
        min_quantity: "Minimum Quantity",
        brand: "Brand",
        actions: "Actions",
        edit: "Edit",
        delete: "Delete",
        loading: "Loading...",
        fetch_error: "Failed to load articles. Please try again later.",
        delete_error: "Failed to delete article. Please try again later.",
        select_brand: "Select a brand",
        edit_article: "Edit Article",
        save_changes: "Save Changes",
        brands: "Brands",
        id: "ID",
        name: "Name",
        fetch_brands_error: "Failed to load brands. Please try again later.",
        delete_brand_error: "Failed to delete brand. Please try again later.",
        edit_brand: "Edit Brand",
        brand_name: "Brand Name",
        fetch_data_error: "Failed to load data. Please try again later.",
        update_success: "Brand updated successfully!",
        update_error: "Failed to update brand. Please try again later.",
        create_success: "Brand created successfully!",
        create_error: "Failed to create brand. Please try again later.",
        full_name: "Full Name",
        username: "Username",
        email: "Email",
        role: "Role",
        fetch_users_error: "Failed to load users. Please try again later.",
        full_name_placeholder: "Enter your full name",
        username_placeholder: "Enter your username",
        email_placeholder: "Enter your email",
        password_placeholder: "Enter your password",
        user: "User",
        moderator: "Moderator",
        admin: "Admin",
        registering: "Registering...",
        register: "Register",
        registration_success: "Registration successful! Redirecting...",
        registration_error: "Failed to register. Please try again later.",
        login_title: "Login - Cedicor",
        login: "Login",
        forgot_password: "Forgot Password?",
        no_account_register: "Don't have an account? Register",
        login_error:
          "Failed to login. Please check your credentials and try again.",
        register_title: "Register - Cedicor",
        register: "Register",
        have_account_login: "Have an account? Login",
        registration_success: "Registration successful!",
        registration_error: "Failed to register. Please try again later.",
      },
    },
    es: {
      translation: {
        dashboard: "Tablero",
        articles: "Artículos",
        article_list: "Lista de Artículos",
        create_article: "Crear Nuevo Artículo",
        categories: "Categorías",
        brand_list: "Lista de Marcas",
        create_brand: "Crear Nueva Marca",
        users: "Usuarios",
        user_list: "Lista de Usuarios",
        create_user: "Crear Nuevo Usuario",
        total_auto_parts: "Total de Repuestos",
        orders_completed: "Órdenes Completadas",
        pending_orders: "Órdenes Pendientes",
        recent_activity: "Actividad Reciente",
        description: "Descripción",
        date: "Fecha",
        order_completed: "Orden #{{orderId}} completada",
        new_auto_part_added: "Nuevo repuesto añadido: {{part}}",
        order_pending: "Orden #{{orderId}} pendiente",
        code: "Código",
        alt_code: "Código Alternativo",
        description: "Descripción",
        price: "Precio",
        stock: "Stock",
        min_quantity: "Cantidad Mínima",
        brand: "Marca",
        actions: "Acciones",
        edit: "Editar",
        delete: "Eliminar",
        loading: "Cargando...",
        fetch_error:
          "No se pudieron cargar los artículos. Por favor, inténtelo más tarde.",
        delete_error:
          "No se pudo eliminar el artículo. Por favor, inténtelo más tarde.",
        select_brand: "Selecciona una marca",
        edit_article: "Editar Artículo",
        save_changes: "Guardar Cambios",
        brands: "Marcas",
        id: "ID",
        name: "Nombre",
        fetch_brands_error:
          "No se pudieron cargar las marcas. Por favor, inténtelo más tarde.",
        delete_brand_error:
          "No se pudo eliminar la marca. Por favor, inténtelo más tarde.",
        edit_brand: "Editar Marca",
        brand_name: "Nombre de la Marca",
        fetch_data_error:
          "No se pudo cargar la información. Por favor, inténtelo más tarde.",
        update_success: "¡Marca actualizada con éxito!",
        update_error:
          "No se pudo actualizar la marca. Por favor, inténtelo más tarde.",
        create_success: "¡Marca creada con éxito!",
        create_error:
          "No se pudo crear la marca. Por favor, inténtelo más tarde.",
        full_name: "Nombre Completo",
        username: "Nombre de Usuario",
        email: "Correo Electrónico",
        role: "Rol",
        fetch_users_error:
          "No se pudo cargar la lista de usuarios. Por favor, inténtelo más tarde.",
        full_name_placeholder: "Ingresa tu nombre completo",
        username_placeholder: "Ingresa tu nombre de usuario",
        email_placeholder: "Ingresa tu correo electrónico",
        password_placeholder: "Ingresa tu contraseña",
        user: "Usuario",
        moderator: "Moderador",
        admin: "Administrador",
        registering: "Registrando...",
        register: "Registrar",
        registration_success: "¡Registro exitoso! Redirigiendo...",
        registration_error:
          "No se pudo registrar. Por favor, inténtelo más tarde.",
        login_title: "Iniciar Sesión - Cedicor",
        login: "Iniciar Sesión",
        forgot_password: "¿Olvidaste tu contraseña?",
        no_account_register: "¿No tienes una cuenta? Regístrate",
        login_error:
          "Error al iniciar sesión. Verifica tus credenciales e intenta nuevamente.",
        register_title: "Registro - Cedicor",
        register: "Registrar",
        have_account_login: "¿Ya tienes una cuenta? Inicia sesión",
        registration_success: "¡Registro exitoso!",
        registration_error:
          "No se pudo registrar. Por favor, inténtelo más tarde.",
      },
    },
  },
  lng: savedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
