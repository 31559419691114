import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Navmenu = () => {
  const { t, i18n } = useTranslation();
  const role = localStorage.getItem("role");
  const isVisible = role === "admin" || role === "moderator";
  const [openSection, setOpenSection] = useState(null);

  const toggleCollapse = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };

  const changeLanguage = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  return (
    <nav className="col-md-2 d-none d-md-block bg-light sidebar">
      <div className="position-sticky">
        <h1 style={{ textAlign: "center" }}>CEDICOR</h1>

        <div className="p-3">
          <select
            onChange={changeLanguage}
            className="form-select form-select-sm"
            defaultValue={i18n.language}
          >
            <option value="en">English</option>
            <option value="es">Español</option>
          </select>
        </div>

        <ul className="nav flex-column">
          <li className="nav-item">
            <a className="nav-link active" href="/dashboard">
              {t("dashboard")}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link d-flex align-items-center gap-2"
              href="#"
              onClick={() => toggleCollapse("articulos")}
              data-bs-toggle="collapse"
              aria-expanded={openSection === "articulos"}
            >
              {t("articles")}
              <FontAwesomeIcon
                icon={openSection === "articulos" ? faArrowDown : faArrowLeft}
                className="me-2"
              />
            </a>
            <div
              className={`collapse ${
                openSection === "articulos" ? "show" : ""
              }`}
            >
              <ul className="list-unstyled ms-3">
                <li>
                  <a className="nav-link" href="/articulos">
                    {t("article_list")}
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="/articulos/crear">
                    {t("create_article")}
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link d-flex align-items-center gap-2"
              href="#"
              onClick={() => toggleCollapse("categorias")}
              data-bs-toggle="collapse"
              aria-expanded={openSection === "categorias"}
            >
              {t("categories")}
              <FontAwesomeIcon
                icon={openSection === "categorias" ? faArrowDown : faArrowLeft}
                className="me-2"
              />
            </a>
            <div
              className={`collapse ${
                openSection === "categorias" ? "show" : ""
              }`}
            >
              <ul className="list-unstyled ms-3">
                <li>
                  <a className="nav-link" href="/marcas">
                    {t("brand_list")}
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="/marcas/crear">
                    {t("create_brand")}
                  </a>
                </li>
              </ul>
            </div>
          </li>
          {isVisible && (
            <li className="nav-item">
              <a
                className="nav-link d-flex align-items-center gap-2"
                href="#"
                onClick={() => toggleCollapse("usuarios")}
                data-bs-toggle="collapse"
                aria-expanded={openSection === "usuarios"}
              >
                {t("users")}
                <FontAwesomeIcon
                  icon={openSection === "usuarios" ? faArrowDown : faArrowLeft}
                  className="me-2"
                />
              </a>
              <div
                className={`collapse ${
                  openSection === "usuarios" ? "show" : ""
                }`}
              >
                <ul className="list-unstyled ms-3">
                  <li>
                    <a className="nav-link" href="/admin/user-list">
                      {t("user_list")}
                    </a>
                  </li>
                  <li>
                    <a className="nav-link" href="/admin/register">
                      {t("create_user")}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navmenu;
