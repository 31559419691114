import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/Brands.css";
import { getBrands } from "../config/api";
import Navmenu from "../components/Navmenu";
import { useTranslation } from "react-i18next";

const Brands = () => {
  const { t } = useTranslation();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const data = await getBrands();
        setBrands(data);
      } catch (err) {
        console.error("Failed to fetch brands", err);
        setError(t("fetch_brands_error"));
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [t]);

  const handleDelete = async (brandId) => {
    // try {
    //   await deleteBrand(brandId);
    //   setBrands((prevBrands) =>
    //     prevBrands.filter((brand) => brand.id !== brandId)
    //   );
    // } catch (err) {
    //   console.error("Failed to delete brand", err);
    //   setError(t("delete_brand_error"));
    // }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <h2 className="text-center my-4">{t("brands")}</h2>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </div>
            </div>
          ) : error ? (
            <div className="alert alert-danger text-center">{error}</div>
          ) : (
            <div className="card shadow-sm p-4">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>{t("id")}</th>
                    <th>{t("name")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {brands.map((brand) => (
                    <tr key={brand.id}>
                      <td>{brand.id}</td>
                      <td>{brand.name}</td>
                      <td>
                        <a
                          href={`/marcas/${brand.id}`}
                          className="btn btn-sm btn-warning me-2"
                        >
                          {t("edit")}
                        </a>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDelete(brand.id)}
                        >
                          {t("delete")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Brands;
