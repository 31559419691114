import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/Articles.css";
import { getArticles } from "../config/api";
import Navmenu from "../components/Navmenu";
import { useTranslation } from "react-i18next";

const Articles = () => {
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await getArticles();
        setArticles(data);
      } catch (err) {
        console.error("Failed to fetch articles", err);
        setError(t("fetch_error"));
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [t]);

  const handleDelete = async (articleId) => {
    // try {
    //   await deleteArticle(articleId);
    //   setArticles((prevArticles) =>
    //     prevArticles.filter((article) => article.id !== articleId)
    //   );
    // } catch (err) {
    //   console.error("Failed to delete article", err);
    //   setError(t("delete_error"));
    // }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <h2 className="text-center my-4">{t("articles")}</h2>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </div>
            </div>
          ) : error ? (
            <div className="alert alert-danger text-center">{error}</div>
          ) : (
            <div className="card shadow-sm p-4">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>{t("code")}</th>
                    <th>{t("alt_code")}</th>
                    <th>{t("description")}</th>
                    <th>{t("price")}</th>
                    <th>{t("stock")}</th>
                    <th>{t("min_quantity")}</th>
                    <th>{t("brand")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {articles.map((article) => (
                    <tr key={article.id}>
                      <td>{article.code}</td>
                      <td>{article.alternative_code}</td>
                      <td>{article.description}</td>
                      <td>{article.price}</td>
                      <td>{article.stock}</td>
                      <td>{article.minimum_quantity}</td>
                      <td>{article.brands.name}</td>
                      <td>
                        <a
                          href={`/articulos/${article.id}`}
                          className="btn btn-sm btn-warning me-2"
                        >
                          {t("edit")}
                        </a>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDelete(article.id)}
                        >
                          {t("delete")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Articles;
