import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/BrandsEdit.css";
import { getBrandById, updateBrand } from "../config/api";
import { useParams, useNavigate } from "react-router-dom";
import Navmenu from "../components/Navmenu";
import { useTranslation } from "react-i18next";

const BrandsEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [brand, setBrand] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const brandData = await getBrandById(id);
        setBrand(brandData);
      } catch (err) {
        console.error("Failed to fetch brand", err);
        setError(t("fetch_data_error"));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, t]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setBrand((prevBrand) => ({
      ...prevBrand,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // setError(null);
    // try {
    //   await updateBrand(id, brand);
    //   alert(t("update_success"));
    //   navigate("/brands");
    // } catch (err) {
    //   console.error("Failed to update brand", err);
    //   setError(t("update_error"));
    // }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 d-flex flex-column justify-content-center align-items-center">
          <div
            className="card shadow-lg p-4"
            style={{ maxWidth: "600px", width: "100%", margin: "3em 0" }}
          >
            <h2 className="text-center mb-4">{t("edit_brand")}</h2>
            {loading ? (
              <div className="text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">{t("loading")}</span>
                </div>
              </div>
            ) : error ? (
              <div className="alert alert-danger text-center">{error}</div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    {t("brand_name")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={brand ? brand.name : ""}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button type="submit" className="btn btn-success w-100 mt-3">
                    {t("save_changes")}
                  </button>
                </div>
              </form>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default BrandsEdit;
