import axios from "axios";

const API_BASE_URL = "https://api-386v.onrender.com";

// Set up Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = async ({ email, password }) => {
  try {
    const response = await api.post("/api/users/login", { email, password });
    localStorage.setItem("user", response.data.user);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("role", response.data.user.role);
    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

export const register = async ({ name, username, email, password, role }) => {
  try {
    const response = await api.post("/api/users/register", {
      name,
      username,
      email,
      password,
      role,
    });
    localStorage.setItem("user", response.data.user);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("role", response.data.user.role);
    return response.data;
  } catch (error) {
    console.error("Registration failed:", error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

export const getUsers = async () => {
  try {
    const response = await api.get("/api/users");
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getArticles = async () => {
  try {
    const response = await api.get("/api/articles");
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getBrands = async () => {
  try {
    const response = await api.get("/api/brands");
    return response.data;
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getArticleById = async (id_article) => {
  try {
    const response = await api.get(`/api/articles/${id_article}`);
    return response.data[0];
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export const getBrandById = async (id_brand) => {
  try {
    const response = await api.get(`/api/brands/${id_brand}`);
    return response.data[0];
  } catch (error) {
    console.error("failed:", error);
    throw error;
  }
};

export default api;
