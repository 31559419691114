import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/ArticlesCreate.css";
import { createArticle, getBrands } from "../config/api";
import { useNavigate } from "react-router-dom";
import Navmenu from "../components/Navmenu";
import { useTranslation } from "react-i18next";

const ArticlesCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [article, setArticle] = useState({
    code: "",
    alternative_code: "",
    description: "",
    price: 0,
    stock: 0,
    minimum_quantity: 0,
    brand_id: "",
  });
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const data = await getBrands();
        setBrands(data);
      } catch (err) {
        console.error("Failed to fetch brands", err);
        setError(t("fetch_brands_error"));
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [t]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setArticle((prevArticle) => ({
      ...prevArticle,
      [id]:
        id === "price" || id === "stock" || id === "minimum_quantity"
          ? Number(value)
          : value,
    }));
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // setError(null);
    // try {
    //   await createArticle(article);
    //   alert(t("article_created_success"));
    //   navigate("/articles");
    // } catch (err) {
    //   console.error("Failed to create article", err);
    //   setError(t("create_article_error"));
    // }
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <Navmenu />

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 d-flex flex-column justify-content-center align-items-center">
          <div
            className="card shadow-lg p-4"
            style={{ maxWidth: "600px", width: "100%", margin: "3em 0" }}
          >
            <h2 className="text-center mb-4">{t("create_article")}</h2>
            {loading ? (
              <div className="text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">{t("loading")}</span>
                </div>
              </div>
            ) : error ? (
              <div className="alert alert-danger text-center">{error}</div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="code" className="form-label">
                    {t("code")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="code"
                    value={article.code}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="alternative_code" className="form-label">
                    {t("alt_code")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="alternative_code"
                    value={article.alternative_code}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    {t("description")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    value={article.description}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="price" className="form-label">
                    {t("price")}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    value={article.price}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="stock" className="form-label">
                    {t("stock")}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="stock"
                    value={article.stock}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="minimum_quantity" className="form-label">
                    {t("min_quantity")}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="minimum_quantity"
                    value={article.minimum_quantity}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="brand_id" className="form-label">
                    {t("brand")}
                  </label>
                  <select
                    className="form-control"
                    id="brand_id"
                    value={article.brand_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      {t("select_brand")}
                    </option>
                    {brands.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <button type="submit" className="btn btn-success w-100 mt-3">
                    {t("create_article")}
                  </button>
                </div>
              </form>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ArticlesCreate;
