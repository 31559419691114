import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./language/i18n";
import logo from "./logo.svg";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterAdmin from "./pages/admin/Register";
import Dashboard from "./pages/dashboard";
import UserList from "./pages/admin/UserList";
import Articles from "./pages/Articles";
import Brands from "./pages/Brands";
import ArticlesEdit from "./pages/ArticlesEdit";
import BrandsEdit from "./pages/BrandsEdit";
import ArticlesCreate from "./pages/ArticlesCreate";
import BrandsCreate from "./pages/BrandsCreate";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin/register" element={<RegisterAdmin />} />
          <Route path="/admin/user-list" element={<UserList />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/articulos" element={<Articles />} />
          <Route path="/articulos/:id" element={<ArticlesEdit />} />
          <Route path="/articulos/crear" element={<ArticlesCreate />} />
          <Route path="/marcas" element={<Brands />} />
          <Route path="/marcas/:id" element={<BrandsEdit />} />
          <Route path="/marcas/crear" element={<BrandsCreate />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
